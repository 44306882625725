<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <h2>Team of Editorials</h2>
            <a routerLink="/editorial" class="default-btn"
                ><i class="bx bx-show-alt icon-arrow before"></i
                ><span class="label">Meet All Editorials </span
                ><i class="bx bx-show-alt icon-arrow after"></i
            ></a>
        </div>
        <span class="sub-title">Editor-in-Chief</span>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-team-card mb-30" *ngIf="ceo">
                    <img
                        [src]="ceo.image"
                        alt="{{ ceo.name }}"
                        [routerLink]="['/editorial', ceo.id]"
                    />
                    <div class="team-content">
                        <h3>{{ ceo.name }}</h3>
                        <span>{{ ceo.job }}</span>
                        <div class="social-btn">
                            <span><i class="bx bxs-share-alt"></i></span>
                            <ul>
                                <li>
                                    <a
                                        href="https://www.facebook.com/envato"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://twitter.com/"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <span class="sub-title"
            >Associate Editors <br />
            Co-Editor</span
        >
        <div class="row justify-content-center">
            <div
                class="col-lg-4 col-md-6"
                *ngFor="let editorial of filteredEditorialBoard"
            >
                <div class="single-team-card mb-30">
                    <img
                        [src]="editorial.image"
                        alt="{{ editorial.name }}"
                        [routerLink]="['/editorial', editorial.id]"
                    />
                    <div class="team-content">
                        <h3>{{ editorial.name }}</h3>
                        <span>{{ editorial.job }}</span>
                        <div class="social-btn">
                            <span><i class="bx bxs-share-alt"></i></span>
                            <ul>
                                <li>
                                    <a
                                        href="https://www.facebook.com/envato"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://twitter.com/"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
