<app-header></app-header>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Information</li>
            </ul>
            <h2>Information</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <ul class="nav-tabset">
                <li
                    class="nav-tab"
                    *ngFor="let tab of informationCategory"
                    [ngClass]="{ active: currentTab === tab.id }"
                >
                    <span class="active" (click)="switchTab($event, tab.id)">
                        {{ tab.name }}
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <ng-container *ngFor="let info of filteredInformation">
                    <div class="pane">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="faq-item">
                                    <div [innerHTML]="info.content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
