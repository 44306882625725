import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { News } from "../interfaces/news";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class NewsService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<News[]> {
        return this.http.get<News[]>(`${environment.apiUrl}/Home/GetNews`);
    }

    getNewsById(id: number): Observable<News[]> {
        return this.http.get<News[]>(`${environment.apiUrl}/News/${id}`);
    }
}
