<app-header></app-header>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>For Authors</li>
            </ul>
            <h2>Information For Authors</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <div class="tabs-container">
                <div class="pane custom">
                    <div class="row justify-content-center">
                        <div class="my-dashboard-area ptb-100">
                            <div class="myDashboard-content">
                                <div class="downloads-table table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Item Name</th>
                                                <th>Download</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a>Submission checklist</a>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/assets/files/Submission checklist.docx"
                                                        class="downloads-button"
                                                        >Click Here</a
                                                    >
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a
                                                        >template of journal
                                                        form</a
                                                    >
                                                </td>
                                                <td>
                                                    <a
                                                        href="/assets/files/template of journal form.docx"
                                                        class="downloads-button"
                                                        >Click Here</a
                                                    >
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a
                                                        >The process of handling
                                                        cases requiring
                                                        corrections, retraction,
                                                        and editorial expression
                                                        of concern</a
                                                    >
                                                </td>
                                                <td>
                                                    <a
                                                        href="/assets/files/The process of handling cases requiring corrections, retraction, and editorial expression of concern.docx"
                                                        class="downloads-button"
                                                        >Click Here</a
                                                    >
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
