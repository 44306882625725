import { Component, OnInit } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ArticleService } from "src/app/services/article.service";
import { environment } from "src/environments/environment";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Router } from "@angular/router";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    baseUrl = environment.baseUrl;
    list: Article[] = [];
    filteredList: Article[] = [];
    faDownload = faDownload;
    faEye = faEye;

    constructor(
        private router: Router,
        private articleService: ArticleService
    ) {}

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.articleService.getAll().subscribe((data) => {
            this.list = data;
        });
    }
}
