<app-header></app-header>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Open Access Policy</li>
            </ul>
            <h2>Open Access Policy</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <div class="tabs-container">
                <div class="pane">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>
                                    IUJAS is a peer reviewed, open access
                                    journal.
                                </h3>
                                <h3>User rights</h3>
                                <p>
                                    All articles published open access will be
                                    immediately and permanently free for
                                    everyone to read, download, copy and
                                    distribute. Permitted reuse is defined by
                                    <strong
                                        >Creative Commons
                                        Attribution-NonCommercial-NoDerivs (CC
                                        BY-NC-ND)</strong
                                    >
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>Article Publishing Charge</h3>
                                <p>
                                    As an open access journal with no
                                    subscription charges, a fee (Article
                                    Publishing Charge, APC) is payable by the
                                    author or research funder to cover the costs
                                    associated with publication. This ensures
                                    your article will be immediately and
                                    permanently free to access by everyone. The
                                    Article Publishing Charge for this journal
                                    is:
                                </p>
                                <table>
                                    <th>Researcher type</th>
                                    <th>
                                        Article Publishing Charge (excl. taxes)
                                    </th>
                                    <tr>
                                        <td>International</td>
                                        <td>USD 300</td>
                                    </tr>
                                    <tr>
                                        <td>Local</td>
                                        <td>USD 150</td>
                                    </tr>
                                </table>
                                <p>Discounts to the charges mentioned above:</p>
                                <ul>
                                    <li>
                                        For articles submitted between 1 October
                                        2023 and 30 September 2024 there is a
                                        50% introduction discount
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
