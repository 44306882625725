import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { informationCategory } from "../interfaces/information-category";

@Injectable({
    providedIn: "root",
})
export class InformationCategoryService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<informationCategory[]> {
        return this.http.get<informationCategory[]>(`${environment.apiUrl}/InformationCategory/`);
    }

    getInformationCategoryById(id: number): Observable<informationCategory[]> {
        return this.http.get<informationCategory[]>(`${environment.apiUrl}/InformationCategory/${id}`);
    }
}