<app-header></app-header>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Policies</li>
            </ul>
            <h2>Policies</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <ul class="nav-tabset">
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab1' }"
                >
                    <span class="active" (click)="switchTab($event, 'tab1')"
                        >Advertising Policy</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab2' }"
                >
                    <span (click)="switchTab($event, 'tab2')"
                        >Disclaimer Policy</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab3' }"
                >
                    <span (click)="switchTab($event, 'tab3')"
                        >Editorial Policies</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab4' }"
                >
                    <span (click)="switchTab($event, 'tab4')"
                        >Informed consent policy</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab5' }"
                >
                    <span (click)="switchTab($event, 'tab5')"
                        >Peer review policy</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab6' }"
                >
                    <span (click)="switchTab($event, 'tab6')"
                        >Policies on Conflict of Interest</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab7' }"
                >
                    <span (click)="switchTab($event, 'tab7')"
                        >Repository Policy</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab8' }"
                >
                    <span (click)="switchTab($event, 'tab8')"
                        >Research ethics policy</span
                    >
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>
                                    Our advertising policy at Israa University
                                    Journal of Applied Sciences (IUJAS) is
                                    designed to ensure that any advertising
                                    within the journal aligns with our
                                    commitment to scholarly excellence,
                                    scientific integrity, and the interests of
                                    our readership.
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Advertisement Approval</h3>
                                <p>
                                    Advertisements in IUJAS will be subject to
                                    review and approval by the journal's
                                    editorial team. All advertising content,
                                    including visuals, text, and links, must
                                    adhere to our guidelines and standards.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Acceptable Advertisement Content</h3>
                                <p>
                                    Advertisements that are directly related to
                                    the fields of Applied Science, Engineering,
                                    Medical Sciences, and Technology, and are
                                    relevant to our readership, may be
                                    considered for publication. These may
                                    include:
                                </p>
                                <ul>
                                    <li>
                                        Academic conferences, workshops, and
                                        seminars.
                                    </li>
                                    <li>
                                        Scientific events, symposiums, and
                                        webinars.
                                    </li>
                                    <li>
                                        Educational programs, courses, and
                                        workshops.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Inappropriate Content</h3>
                                <p>
                                    Advertisements promoting content that
                                    conflicts with our journal's ethical
                                    standards, mission, or values will not be
                                    accepted.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Placement and Design</h3>
                                <p>
                                    IUJAS reserves the right to determine the
                                    placement and design of advertisements
                                    within the journal. Advertisements will be
                                    clearly distinguished from editorial content
                                    to prevent confusion among readers.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Transparency and Disclosure</h3>
                                <p>
                                    Any advertisement included in IUJAS will be
                                    clearly labeled as such to maintain
                                    transparency and avoid any ambiguity between
                                    editorial content and advertising material.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Non-Influence on Editorial Process</h3>
                                <p>
                                    Advertisements will have no influence on the
                                    editorial process, including article
                                    selection, peer review, or editorial
                                    decisions.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Review and Updates</h3>
                                <p>
                                    Our advertising policy will be periodically
                                    reviewed and updated as necessary to ensure
                                    its alignment with our journal's mission.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Disclaimer</h3>
                                <p>
                                    IUJAS does not endorse or take
                                    responsibility for the products, services,
                                    or claims made in advertisements.
                                    Advertisers are solely responsible for the
                                    accuracy and content of their
                                    advertisements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>Disclaimer Policy</h3>
                                <p>
                                    The information contained in this website is
                                    for Educational purposes only. The
                                    information is provided by Israa university
                                    journal of applied sciences (IUJAS) and
                                    while we endeavor to keep the information up
                                    to date and correct, we make no
                                    representations or warranties of any kind,
                                    express or implied, about the completeness,
                                    accuracy, reliability, suitability or
                                    availability with respect to the website or
                                    the information, products, services, or
                                    related graphics contained on the website
                                    for any purpose. Any reliance you place on
                                    such information is therefore strictly at
                                    your own risk.
                                </p>
                                <p>
                                    In no event will we be liable for any loss
                                    or damage including without limitation,
                                    indirect or consequential loss or damage, or
                                    any loss or damage whatsoever arising from
                                    loss of data or profits arising out of, or
                                    in connection with, the use of this website.
                                </p>
                                <p>
                                    Through this website you are able to link to
                                    other websites which are not under the
                                    control of Israa university journal of
                                    applied sciences (IUJAS). We have no control
                                    over the nature, content and availability of
                                    those sites. The inclusion of any links does
                                    not necessarily imply a recommendation or
                                    endorse the views expressed within them.
                                </p>
                                <p>
                                    Every effort is made to keep the website up
                                    and running smoothly. However, Israa
                                    university journal of applied sciences
                                    (IUJAS) takes no responsibility for, and
                                    will not be liable for, the website being
                                    temporarily unavailable due to technical
                                    issues beyond our control. Regards,
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>Editorial Policies</h3>
                                <p>
                                    All papers submitted to the ISRAA UNIVERSITY
                                    of APPLIED SCIENCES (IUJS) will undergo peer
                                    review before being accepted for
                                    publication. The main objective of the
                                    review process is to ensure that papers are
                                    original, with significant contribution, and
                                    well presented.
                                </p>
                                <ol>
                                    <li>
                                        The Editorial Board Member must provide
                                        an initial quality check to the paper
                                        and whether the author is presenting the
                                        material logically, in clear and direct
                                        language, and as concise as possible.
                                    </li>
                                    <li>
                                        The paper will be checked for similarity
                                        using Turnitin software. Any paper with
                                        20% or higher similarity will be
                                        returned to the Author (s).
                                    </li>
                                    <li>
                                        All reviews are double-blind. Any
                                        references to the authors' names should
                                        be removed before the paper is submitted
                                        for review
                                    </li>
                                    <li>
                                        The peer-review team are request not
                                        publish the research or any part of the
                                        results of the papers deal with in any
                                        way, as the papers must be dealt with as
                                        confidential.
                                    </li>
                                    <li>
                                        The peer review team are request to
                                        report any conflicts of interest, if
                                        any. Notify the Editor-in-Chief (EIC)
                                        immediately if you are in direct
                                        competition with the authors or
                                        currently working with, any of the
                                        authors.
                                    </li>
                                    <li>
                                        Papers submitted to IUJAS must be
                                        original work and not published or under
                                        review by another journal.
                                    </li>
                                    <li>
                                        An appropriate warning shall be given to
                                        any reviewer who violates the
                                        "Guidelines for Reviewers. If the
                                        violation is repeated, the reviewer must
                                        be banned permanently.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab4'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>Informed consent policy</h3>
                                <p>
                                    In
                                    <strong
                                        >Israa Journal of Applied science
                                        (IUJAS)</strong
                                    >, patients have a right to privacy that
                                    should not be violated without informed
                                    consent. Identifying information, including
                                    names, initials, or hospital numbers, should
                                    not be published in written descriptions,
                                    photographs, or pedigrees unless the
                                    information is essential for scientific
                                    purposes and the patient (or parent or
                                    guardian) gives written informed consent for
                                    publication. Informed consent for this
                                    purpose requires that an identifiable
                                    patient be shown the manuscript to be
                                    published. Authors should disclose to these
                                    patients whether any potential identifiable
                                    material might be available via the Internet
                                    as well as in print after publication.
                                    Patient consent should be written and
                                    archived either with the journal, the
                                    authors, or both, as dictated by local
                                    regulations or laws. Nonessential
                                    identifying details should be omitted.
                                    Informed consent should be obtained if there
                                    is any doubt that anonymity can be
                                    maintained. For example, masking the eye
                                    region in photographs of patients is
                                    inadequate protection of anonymity. If
                                    identifying characteristics are altered to
                                    protect anonymity, such as in genetic
                                    pedigrees, authors should provide assurance,
                                    and editors should so note, that such
                                    alterations do not distort scientific
                                    meaning. When informed consent has been
                                    obtained, it should be indicated in the
                                    published article.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab5'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <p>
                                    The practice of peer review is intended to
                                    ensure that only good science is published.
                                    As an objective method of guaranteeing
                                    excellence in scholarly publishing, it has
                                    been adopted by all reputable scientific
                                    journals. Our referees play a vital role in
                                    maintaining the high standards of the Israa
                                    Journal of Applied Science (IUJAS), which is
                                    why all incoming manuscripts are peer
                                    reviewed following the procedure outlined
                                    below.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h4>Initial manuscript evaluation</h4>
                                <p>
                                    One of the Chief Editors first evaluates all
                                    submitted manuscripts. It is rare, but it is
                                    possible for an exceptional manuscript to be
                                    accepted at this stage. Manuscripts rejected
                                    at this stage are insufficiently original,
                                    have serious scientific flaws, have poor
                                    grammar or English language, or are outside
                                    the aims and scope of the journal. Those
                                    that meet the minimum criteria are normally
                                    passed on to at least two expert referees
                                    for reviewing.
                                </p>
                                <p>
                                    Authors of manuscripts rejected at this
                                    stage will usually be informed within two
                                    weeks of receipt.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h4>Type of peer review</h4>
                                <p>
                                    The <strong>IUJAS</strong> employs 'double
                                    blind' reviewing, in which the referees
                                    remain anonymous to the author(s) throughout
                                    and following the refereeing process, whilst
                                    the identity of the author(s) is likewise
                                    unknown to the reviewers.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h4>How the referee is selected</h4>
                                <p>
                                    Whenever possible, referees are matched to
                                    the paper according to their expertise. As
                                    our reviewer database is constantly being
                                    updated, we welcome suggestions for referees
                                    from the author(s), though such non-binding
                                    recommendations are not necessarily used.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h4>Referee reports</h4>
                                <ul>
                                    <li>
                                        Is original as to thought and method
                                        (including data)
                                    </li>
                                    <li>Is methodologically sound</li>
                                    <li>
                                        Has results which are clearly presented
                                        and support the conclusions
                                    </li>
                                    <li>
                                        Correctly and exhaustively references
                                        previous relevant work
                                    </li>
                                    <li>
                                        Follows appropriate ethical guidelines,
                                        especially as concerns plagiarism
                                    </li>
                                    <li>
                                        Clearly adds to the knowledge and
                                        development of the field
                                    </li>
                                </ul>
                                <p>
                                    Language correction is not part of the peer
                                    review process, but referees are encouraged
                                    to suggest corrections of language and style
                                    to the manuscript. In the final round, the
                                    handling Editor will check matters of
                                    linguistic and stylistic correctness, and
                                    may suggest or apply corrections at this
                                    point. In rare cases, the manuscript may be
                                    returned to the author(s) for a full
                                    linguistic and stylistic revision.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h4>How long does the review process take?</h4>
                                <p>
                                    The time required for the review process is
                                    dependent on the response of the referees.
                                    For the
                                    <strong>IUJAS</strong>, the typical time for
                                    the first round of the refereeing process is
                                    approximately 4 weeks, with a maximum of two
                                    months. Should the referees' reports
                                    contradict one another or a report is
                                    unnecessarily delayed, a further expert
                                    opinion may be sought. In the rare cases
                                    when it is extremely difficult to find a
                                    second referee to review the manuscript,
                                    whilst the one referee's extant report has
                                    thoroughly convinced the handling Editor, a
                                    decision to accept, reject or ask the author
                                    for a revision may be made, at the handling
                                    Editor's discretion, on the basis of only
                                    one referee report. The handling Editor's
                                    decision will be sent to the author with the
                                    referees' recommendations, usually including
                                    the latter's verbatim comments. As a rule,
                                    revised manuscripts are sent to the initial
                                    referees for checking; these may then
                                    request further revision.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h4>Final report</h4>
                                <p>
                                    A final decision to accept or reject the
                                    manuscript will be sent to the author along
                                    with the recommendations made by the
                                    referees, including (if applicable) the
                                    latter's verbatim comments.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h4>The Chief Editor's decision is final</h4>
                                <p>
                                    Referees advise the Chief Editor, who is
                                    responsible for the final decision to accept
                                    or reject the article.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab6'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h2>
                                    Policies on Conflict of Interest, Human and
                                    Animal rights
                                </h2>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Conflict of Interest</h3>
                                <p>
                                    A Declaration of Conflicting Interests
                                    policy refers to a formal policy a journal
                                    may have to require a conflict of interest
                                    statement or conflict of interest disclosure
                                    from a submitting or publishing author.
                                </p>
                                <p>
                                    'Conflicts of interest (CIO) arise when
                                    authors, reviewers, or editors have
                                    interests that are not fully apparent and
                                    that may influence their judgments on what
                                    is published. They have been described as
                                    those which, when revealed later, would make
                                    a reasonable reader feel misled or
                                    deceived.'
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Reviewers</h3>
                                <p>
                                    By agreeing to peer review a manuscript, the
                                    Reviewers are providing an essential neutral
                                    assessment. As such, the Editors should
                                    ensure that the Reviewers have no conflicts
                                    of interest this thing prevents the
                                    Reviewers from unfair acts.
                                </p>
                                <p>
                                    The Reviewers should ensure that the
                                    Reviewers have no recent association with
                                    the author and have not previously
                                    co-authored them. The Reviewers should also
                                    not have a recent shared employment history.
                                </p>
                                <p>
                                    The
                                    <strong
                                        >Israa Journal of Applied science
                                        (IUJAS)</strong
                                    >, operates a 'double-blind' approach to
                                    peer review. The Reviewer's name will not be
                                    made available to the authors. It allows the
                                    Reviewers to provide honest, pertinent
                                    feedback.
                                </p>
                                <p>
                                    Minor conflicts do not disqualify a reviewer
                                    from reporting on an article but will be
                                    taken into account when considering the
                                    referees' recommendations.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Authors</h3>
                                <p>
                                    As a member of a journal's Editorial Board,
                                    the editors need to be very aware of the
                                    risk of conflicts when handling a
                                    manuscript.
                                </p>
                                <p>
                                    Firstly, the Editor should assess the
                                    author's potential conflicts. If the editors
                                    have recently co-authored with the author of
                                    the manuscript, the Editor could be
                                    perceived to be influenced by the Editor's
                                    relationship. Similarly, if the Editor has
                                    recently shared an affiliation or employment
                                    history with the author, it could also be
                                    seen to be inappropriate for the Editor to
                                    handle their work. The IUJAS, aims to avoid
                                    assigning papers to Editors who might have
                                    conflicts, but we also expect our Editors to
                                    declare any conflicts. If the Editor
                                    believes a conflict exists, the Editor
                                    should refuse to handle the manuscript.
                                </p>
                                <p>
                                    As a subject expert, the
                                    <strong>IUJAS</strong>, relies on the
                                    Editor's knowledge of the discipline to
                                    assess any conflicts declared by a
                                    submitting author. The Editor is also
                                    uniquely placed to be able to identify any
                                    undeclared conflicts that an author might
                                    have. It would be best if the Editor thought
                                    about these factors when making a
                                    recommendation on the manuscript.
                                </p>
                                <p>
                                    It would be best if the Editor also
                                    considered potential conflicts when
                                    assigning the manuscript to reviewers. The
                                    <strong>IUJAS</strong> performs conflict of
                                    interest checks on all reviewers before
                                    receiving the manuscript for review, but the
                                    Editor should also rely on the Editor's
                                    knowledge of the sector to inform the
                                    assignments. Typically, the Editor should
                                    not select a referee who:
                                </p>
                                <ul>
                                    <li>
                                        works or has recently worked at the same
                                        institution as the author; or
                                    </li>
                                    <li>
                                        has recently co-authored a paper with
                                        the author; or
                                    </li>
                                    <li>
                                        has a recent or current collaboration
                                        with the author.
                                    </li>
                                </ul>
                                <p>
                                    Discretion may be applied when publications
                                    are authored by a consortium. If the Editor
                                    has concerns about a potential reviewer,
                                    consider appointing someone else. If the
                                    Editor believes a reviewer's recommendation
                                    on a manuscript was made to further their
                                    interests, the Editor may tell the authors
                                    they do not need to address that point.
                                </p>
                                The <strong>IUJAS</strong> is aware that certain
                                specialist areas may involve a higher likelihood
                                of association and overlap between researchers.
                                In some instances, the Editor may be the
                                best-placed individual to act as Editor despite
                                a connection with the author or authors. In this
                                case, the authors should inform the
                                <strong>IUJAS</strong> editorial contact. They
                                can then refer the case for review by our
                                Research Integrity team.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Editors</h3>
                                <p>
                                    Editors should not make any editorial
                                    decisions or get involved in the editorial
                                    process if they have any COI (financial or
                                    otherwise) for a submitted manuscript.
                                </p>
                                <p>
                                    An editor may have COI if a manuscript is
                                    submitted from their own academic department
                                    or from their institution in such
                                    situations; they should have explicit
                                    policies for managing it.
                                </p>
                                <p>
                                    When editors submit their own work to their
                                    journal, a colleague in the editorial office
                                    should manage the manuscript and the
                                    editor/author should recuse himself or
                                    herself from discussion and decisions about
                                    it.
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="faq-item">
                                    <h3>Human and Animal Rights</h3>
                                    <p>
                                        All research must have been carried out
                                        within an appropriate ethical framework.
                                        If there is suspicion that work has not
                                        taken place within an appropriate
                                        ethical framework, Editors will follow
                                        may reject the manuscript, and/or
                                        contact the author(s)' ethics committee.
                                        On rare occasions, if the Editor has
                                        serious concerns about the ethics of a
                                        study, the manuscript may be rejected on
                                        ethical grounds, even if approval from
                                        an ethics committee has been obtained.
                                    </p>
                                    <ul>
                                        <li>
                                            Articles conducting any animal or
                                            clinical studies should contain a
                                            statement in accordance with the
                                            animal and human ethics committee.
                                        </li>
                                        <li>
                                            Research should be carried out in a
                                            manner that animals do not get
                                            affected unnecessarily.
                                        </li>
                                        <li>
                                            Registration is required for all
                                            clinical trials.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab7'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <p>
                                    Israa University Journal for Applied Science
                                    (IUJAS) allows authors the use of the final
                                    published version of an article or edited
                                    collection chapter (publisher pdf) for
                                    self-archiving (author's personal website)
                                    and/or archiving in an institutional
                                    repository (on a non-profit server).
                                </p>
                                <p>
                                    When posting, distributing or reusing Open
                                    Access articles, the journal should be
                                    clearly attributed as the original place of
                                    publication and correct citation details
                                    should be given. Authors should also deposit
                                    the URL/DOI of their published article in
                                    any repository, in addition to the Version
                                    of Record.
                                </p>
                                <p>
                                    When making their article available
                                    according to the terms of their Open Access
                                    licence, we strongly encourage authors to
                                    deposit the Version of Record. This will
                                    guarantee that the definitive version is
                                    readily available to those accessing your
                                    article from such repositories and means
                                    that your article is more likely to be cited
                                    correctly.
                                </p>
                                <p>
                                    As there are many ways you can share the
                                    different versions of your article as it
                                    moves through the stages towards publication
                                    a summary is provided as follows.
                                </p>
                                <h3>Article versions</h3>
                                <h4>Author's Original Manuscript (AOM)</h4>
                                <p>
                                    You can share your AOM as much as you like,
                                    including via social media, on a scholarly
                                    collaboration network, your own personal
                                    website.
                                </p>
                                <p>
                                    If you do decide to post your AOM anywhere,
                                    we ask that, upon acceptance, you
                                    acknowledge that the article has been
                                    accepted for publication as follows:
                                </p>
                                <p>
                                    "This is an Accepted Manuscript of an
                                    article published in Israa University
                                    Journal for Applied Science on [date of
                                    publication], [Article DOI]."
                                </p>
                                <h4>Version of Record (VOR)</h4>
                                <p>
                                    On publication you can link to the VOR using
                                    the DOI or you can post the article PDF on
                                    any non-commercial websites and
                                    repositories.
                                </p>
                                <p>
                                    By the time your article is published, you
                                    may have already shared your AOM and AM in
                                    various places. It is a good idea to link
                                    these to the VOR after publication, by
                                    adding some text such as:
                                </p>
                                <p>
                                    "This is an [Accepted Manuscript / Author's
                                    Original Manuscript] of an article published
                                    in Israa University Journal for Applied
                                    Science on [date of publication], available
                                    at [Article DOI]."
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab8'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>Duties of the Publisher</h3>
                                <ol>
                                    <li>
                                        Safeguard editorial independence
                                        <p>
                                            We are committed to ensuring that
                                            the potential for advertising,
                                            reprint or other commercial revenue
                                            has no impact or influence on
                                            editorial decisions .
                                        </p>
                                    </li>
                                    <li>
                                        Collaborate to set industry best
                                        practice
                                        <p>
                                            We promote best practice by offering
                                            editors membership of the Committee
                                            on Publication Ethics (COPE) and
                                            providing editors with Crossref
                                            Similarity Check reports for all
                                            submissions to our editorial
                                            systems.
                                        </p>
                                    </li>
                                    <li>
                                        Provide editors with technical,
                                        procedural & legal support
                                        <p>
                                            We support editors in communications
                                            with other journals and/or
                                            publishers where this is useful to
                                            editors and are prepared to provide
                                            specialised legal review and counsel
                                            if necessary.
                                        </p>
                                    </li>
                                    <li>
                                        Educate researchers on publishing ethics
                                        <p>
                                            We also provide extensive education
                                            and advice on publishing ethics
                                            standards, particularly for early
                                            career researchers .
                                        </p>
                                    </li>
                                </ol>
                                <h3>Duties of Editors</h3>
                                <ol>
                                    <li>
                                        Publication decisions
                                        <ul>
                                            <li>
                                                The editor of a IUJAS journal is
                                                solely and independently
                                                responsible for deciding which
                                                of the articles submitted to the
                                                journal should be published,
                                                often working in conjunction
                                                with the relevant society (for
                                                society-owned or sponsored
                                                journals) . The validation of
                                                the work in question and its
                                                importance
                                            </li>
                                            <li>
                                                The editor may be guided by the
                                                policies of the journal's
                                                editorial board and constrained
                                                by such legal requirements as
                                                shall then be in force regarding
                                                issues such as libel, copyright
                                                infringement and plagiarism.
                                            </li>
                                            <li>
                                                The editor may confer with other
                                                editors or reviewers (or society
                                                officers) in making these
                                                decisions.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Peer review
                                        <ul>
                                            <li>
                                                The editor shall ensure that the
                                                peer review process is fair,
                                                unbiased, and timely. Research
                                                articles must typically be
                                                reviewed by at least two
                                                external and independent
                                                reviewers, and where necessary
                                                the editor should seek
                                                additional opinions.
                                            </li>
                                            <li>
                                                The editor shall select
                                                reviewers who have suitable
                                                expertise in the relevant field,
                                                taking account of the need for
                                                appropriate, inclusive and
                                                diverse representation.
                                            </li>
                                            <li>
                                                The editor shall follow best
                                                practice in avoiding the
                                                selection of fraudulent peer
                                                reviewers . The editor shall
                                                review all disclosures of
                                                potential conflicts of interest
                                                and suggestions for
                                                self-citation made by reviewers
                                                in order to determine whether
                                                there is any potential for bias.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Fair play
                                        <ul>
                                            <li>
                                                The editor should evaluate
                                                manuscripts for their
                                                intellectual content without
                                                regard to race, gender, sexual
                                                orientation, religious belief,
                                                ethnic origin, citizenship, or
                                                political philosophy of the
                                                authors. When nominating
                                                potential editorial board
                                                members, the editor shall take
                                                account of the need for
                                                appropriate, inclusive and
                                                diverse representation.
                                            </li>
                                            <li>
                                                The editorial policies of the
                                                journal should encourage
                                                transparency and complete,
                                                honest reporting, and the editor
                                                should ensure that peer
                                                reviewers and authors have a
                                                clear understanding of what is
                                                expected of them.
                                            </li>
                                            <li>
                                                The editor shall use the
                                                journal's standard electronic
                                                submission system for all
                                                journal communications.
                                            </li>
                                            <li>
                                                The editor shall establish,
                                                along with the publisher, a
                                                transparent mechanism for appeal
                                                against editorial decisions.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Journal metrics
                                        <ul>
                                            <li>
                                                The editor must not attempt to
                                                influence the journal's ranking
                                                by artificially increasing any
                                                journal metric. In particular,
                                                the editor shall not require
                                                that references to that (or any
                                                other) journal's articles be
                                                included except for genuine
                                                scholarly reasons and authors
                                                should not be required to
                                                include references to the
                                                editor's own articles or
                                                products and services in which
                                                the editor has an interest.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Confidentiality
                                        <ul>
                                            <li>
                                                The editor must protect the
                                                confidentiality of all material
                                                submitted to the journal and all
                                                communications with reviewers,
                                                unless otherwise agreed with the
                                                relevant authors and reviewers.
                                                In exceptional circumstances and
                                                in consultation with the
                                                publisher, the editor may share
                                                limited information with editors
                                                of other journals, institutions,
                                                and other organizations that
                                                investigate cases of research
                                                misconduct where deemed
                                                necessary to investigate
                                                suspected ethical breaches .
                                            </li>
                                            <li>
                                                Unless the journal is operating
                                                an open peer-review system
                                                and/or reviewers have agreed to
                                                disclose their names, the editor
                                                must protect reviewers'
                                                identities.
                                            </li>
                                            <li>
                                                Unpublished materials disclosed
                                                in a submitted manuscript must
                                                not be used in an editor's own
                                                research without the express
                                                written consent of the author.
                                                Privileged information or ideas
                                                obtained through peer review
                                                must be kept confidential and
                                                not used for personal advantage.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        The use of generative AI and AI-assisted
                                        technologies in the journal editorial
                                        process
                                        <ul>
                                            <li>
                                                This policy has been triggered
                                                by the rise of generative AI and
                                                AI-assisted technologies and
                                                aims to provide greater
                                                transparency and guidance to
                                                authors, editors and reviewers.
                                                IUJAS will monitor ongoing
                                                developments in this area
                                                closely and will adjust or
                                                refine the policy as
                                                appropriate. The following
                                                guidance is specifically for
                                                editors.
                                            </li>
                                        </ul>
                                    </li>
                                    <p>
                                        A submitted manuscript must be treated
                                        as a confidential document. Editors
                                        should not upload a submitted manuscript
                                        or any part of it into a generative AI
                                        tool as this may violate the authors'
                                        confidentiality and proprietary rights
                                        and, where the paper contains personally
                                        identifiable information, may breach
                                        data privacy rights.
                                    </p>
                                    <p>
                                        This confidentiality requirement extends
                                        to all communication about the
                                        manuscript including any notification or
                                        decision letters as they may contain
                                        confidential information about the
                                        manuscript and/or the authors. For this
                                        reason, editors should not upload their
                                        letters into an AI tool, even if it is
                                        just for the purpose of improving
                                        language and readability.
                                    </p>
                                    <li>
                                        Declaration of Competing Interests
                                        <p>
                                            Any potential editorial conflicts of
                                            interest should be declared to the
                                            publisher in writing prior to the
                                            appointment of the editor, and then
                                            updated if and when new conflicts
                                            arise. The publisher may publish
                                            such declarations in the journal.
                                        </p>
                                        <p>
                                            The editor must not be involved in
                                            decisions about papers which s/he
                                            has written him/herself or have been
                                            written by family members or
                                            colleagues or which relate to
                                            products or services in which the
                                            editor has an interest. Further, any
                                            such submission must be subject to
                                            all of the journal's usual
                                            procedures, peer review must be
                                            handled independently of the
                                            relevant author/editor and their
                                            research groups, and there should be
                                            a clear statement to this effect on
                                            any such paper that is published.
                                        </p>
                                    </li>
                                </ol>
                                <h3>Duties of Reviewers</h3>
                                <ol>
                                    <li>
                                        Contribution to Editorial Decisions
                                        <p>
                                            Peer review assists the editor in
                                            making editorial decisions and
                                            through the editorial communications
                                            with the author may also assist the
                                            author in improving the paper. Peer
                                            review is an essential component of
                                            formal scholarly communication, and
                                            lies at the heart of the scientific
                                            method. In addition to the specific
                                            ethics-related duties described
                                            below, reviewers are asked generally
                                            to treat authors and their work as
                                            they would like to be treated
                                            themselves and to observe good
                                            reviewing etiquette.
                                        </p>
                                        <p>
                                            Any selected referee who feels
                                            unqualified to review the research
                                            reported in a manuscript or knows
                                            that its prompt review will be
                                            impossible should notify the editor
                                            and decline to participate in the
                                            review process.
                                        </p>
                                    </li>
                                    <li>
                                        Confidentiality
                                        <p>
                                            Any manuscripts received for review
                                            must be treated as confidential
                                            documents. Reviewers must not share
                                            the review or information about the
                                            paper with anyone or contact the
                                            authors directly without permission
                                            from the editor.
                                        </p>
                                        <p>
                                            Some editors encourage discussion
                                            with colleagues or co-reviewing
                                            exercises, but reviewers should
                                            first discuss this with the editor
                                            in order to ensure that
                                            confidentiality is observed and that
                                            participants receive suitable
                                            credit.
                                        </p>
                                        <p>
                                            Unpublished materials disclosed in a
                                            submitted manuscript must not be
                                            used in a reviewer's own research
                                            without the express written consent
                                            of the author. Privileged
                                            information or ideas obtained
                                            through peer review must be kept
                                            confidential and not used for
                                            personal advantage.
                                        </p>
                                    </li>
                                    <li>
                                        The use of generative AI and AI-assisted
                                        technologies in the journal peer review
                                        process
                                        <p>
                                            When a researcher is invited to
                                            review another researcher's paper,
                                            the manuscript must be treated as a
                                            confidential document. Reviewers
                                            should not upload a submitted
                                            manuscript or any part of it into a
                                            generative AI tool as this may
                                            violate the authors' confidentiality
                                            and proprietary rights and, where
                                            the paper contains personally
                                            identifiable information, may breach
                                            data privacy rights.
                                        </p>
                                        <p>
                                            This confidentiality requirement
                                            extends to the peer review report,
                                            as it may contain confidential
                                            information about the manuscript
                                            and/or the authors. For this reason,
                                            reviewers should not upload their
                                            peer review report into an AI tool,
                                            even if it is just for the purpose
                                            of improving language and
                                            readability.
                                        </p>
                                    </li>
                                    <li>
                                        Alertness to Ethical Issues
                                        <p>
                                            A reviewer should be alert to
                                            potential ethical issues in the
                                            paper and should bring these to the
                                            attention of the editor, including
                                            any substantial similarity or
                                            overlap between the manuscript under
                                            consideration and any other
                                            published paper of which the
                                            reviewer has personal knowledge. Any
                                            statement that an observation,
                                            derivation, or argument had been
                                            previously reported should be
                                            accompanied by the relevant
                                            citation.
                                        </p>
                                    </li>
                                    <li>
                                        Standards of Objectivity & Competing
                                        Interests
                                        <p>
                                            Reviews should be conducted
                                            objectively. Reviewers should be
                                            aware of any personal bias they may
                                            have and take this into account when
                                            reviewing a paper. Personal
                                            criticism of the author is
                                            inappropriate. Referees should
                                            express their views clearly with
                                            supporting arguments.
                                        </p>
                                        <p>
                                            Reviewers should consult the Editor
                                            before agreeing to review a paper
                                            where they have potential conflicts
                                            of interest resulting from
                                            competitive, collaborative, or other
                                            relationships or connections with
                                            any of the authors, companies, or
                                            institutions connected to the
                                            papers.
                                        </p>
                                        <p>
                                            If a reviewer suggests that an
                                            author includes citations to the
                                            reviewer's (or their associates')
                                            work, this must be for genuine
                                            scientific reasons and not with the
                                            intention of increasing the
                                            reviewer's citation count or
                                            enhancing the visibility of their
                                            work (or that of their associates).
                                        </p>
                                    </li>
                                </ol>
                                <h3>Duties of Authors</h3>
                                <ol>
                                    <li>
                                        Reporting Standards
                                        <p>
                                            Authors of reports of original
                                            research should present an accurate
                                            account of the work performed as
                                            well as an objective discussion of
                                            its significance. Underlying data
                                            should be represented accurately in
                                            the paper. A paper should contain
                                            sufficient detail and references to
                                            permit others to replicate the work.
                                            Fraudulent or knowingly inaccurate
                                            statements constitute unethical
                                            behaviour and are unacceptable.
                                        </p>
                                        <p>
                                            Review and professional publication
                                            articles should also be accurate and
                                            objective, and editorial �opinion'
                                            works should be clearly identified
                                            as such.
                                        </p>
                                    </li>
                                    <li>
                                        Data Access and Retention
                                        <p>
                                            Authors may be asked to provide the
                                            research data supporting their paper
                                            for editorial review and/or to
                                            comply with the open data
                                            requirements of the journal. Authors
                                            should be prepared to provide public
                                            access to such data, if practicable,
                                            and should be prepared to retain
                                            such data for a reasonable number of
                                            years after publication. Authors may
                                            refer to their journal's Guide for
                                            Authors for further details.
                                        </p>
                                    </li>
                                    <li>
                                        Originality and Acknowledgement of
                                        Sources
                                        <p>
                                            The authors should ensure that they
                                            have written entirely original
                                            works, and if the authors have used
                                            the work and/or words of others,
                                            that this has been appropriately
                                            cited or quoted and permission has
                                            been obtained where necessary.
                                        </p>
                                        <p>
                                            Proper acknowledgment of the work of
                                            others must always be given. Authors
                                            should cite publications that have
                                            influenced the reported work and
                                            that give the work appropriate
                                            context within the larger scholarly
                                            record. Information obtained
                                            privately, as in conversation,
                                            correspondence, or discussion with
                                            third parties, must not be used or
                                            reported without explicit, written
                                            permission from the source.
                                        </p>
                                        <p>
                                            Plagiarism takes many forms, from
                                            �passing off' another's paper as the
                                            author's own paper, to copying or
                                            paraphrasing substantial parts of
                                            another's paper (without
                                            attribution), to claiming results
                                            from research conducted by others.
                                            Plagiarism in all its forms
                                            constitutes unethical behaviour and
                                            is unacceptable.
                                        </p>
                                    </li>
                                    <li>
                                        Multiple, Redundant or Concurrent
                                        Publication
                                        <p>
                                            An author should not in general
                                            publish manuscripts describing
                                            essentially the same research in
                                            more than one journal of primary
                                            publication. Submitting the same
                                            manuscript to more than one journal
                                            concurrently constitutes unethical
                                            behaviour and is unacceptable.
                                        </p>
                                        <p>
                                            In general, an author should not
                                            submit for consideration in another
                                            journal a paper that has been
                                            published previously, except in the
                                            form of an abstract or as part of a
                                            published lecture or academic thesis
                                            or as an electronic preprint.
                                        </p>
                                        <p>
                                            Publication of some kinds of
                                            articles (e.g. clinical guidelines,
                                            translations) in more than one
                                            journal is sometimes justifiable,
                                            provided certain conditions are met.
                                            The authors and editors of the
                                            journals concerned must agree to the
                                            secondary publication, which must
                                            reflect the same data and
                                            interpretation of the primary
                                            document. The primary reference must
                                            be cited in the secondary
                                            publication.
                                        </p>
                                    </li>
                                    <li>
                                        Confidentiality
                                        <p>
                                            Information obtained in the course
                                            of confidential services, such as
                                            refereeing manuscripts or grant
                                            applications, must not be used
                                            without the explicit written
                                            permission of the author of the work
                                            involved in these services.
                                        </p>
                                    </li>
                                    <li>
                                        Authorship of the Paper
                                        <p>
                                            Authorship should be limited to
                                            those who have made a significant
                                            contribution to the conception,
                                            design, execution, or interpretation
                                            of the reported study. All those who
                                            have made substantial contributions
                                            should be listed as co-authors.
                                        </p>
                                        <p>
                                            Where there are others who have
                                            participated in certain substantive
                                            aspects of the paper (e.g. language
                                            editing or medical writing), they
                                            should be recognised in the
                                            acknowledgements section.
                                        </p>
                                        <p>
                                            The corresponding author should
                                            ensure that all appropriate
                                            co-authors and no inappropriate
                                            co-authors are included on the
                                            paper, and that all co-authors have
                                            seen and approved the final version
                                            of the paper and have agreed to its
                                            submission for publication.
                                        </p>
                                        <p>
                                            Authors are expected to consider
                                            carefully the list and order of
                                            authors before submitting their
                                            manuscript and provide the
                                            definitive list of authors at the
                                            time of the original submission.
                                            Only in exceptional circumstances
                                            will the Editor consider (at their
                                            discretion) the addition, deletion
                                            or rearrangement of authors after
                                            the manuscript has been submitted
                                            and the author must clearly flag any
                                            such request to the Editor. All
                                            authors must agree with any such
                                            addition, removal or rearrangement.
                                        </p>
                                        <p>
                                            Authors take collective
                                            responsibility for the work. Each
                                            individual author is accountable for
                                            ensuring that questions related to
                                            the accuracy or integrity of any
                                            part of the work are appropriately
                                            investigated and resolved.
                                        </p>
                                    </li>
                                    <li>
                                        The Use of Generative AI and AI-assisted
                                        Technologies in Scientific Writing
                                        <p>
                                            This policy has been triggered by
                                            the rise of generative AI and
                                            AI-assisted technologies which are
                                            expected to increasingly be used by
                                            content creators. The policy aims to
                                            provide greater transparency and
                                            guidance to authors, readers,
                                            reviewers, editors and contributors.
                                            Elsevier will monitor this
                                            development and will adjust or
                                            refine this policy when appropriate.
                                            Please note the policy only refers
                                            to the writing process, and not to
                                            the use of AI tools to analyze and
                                            draw insights from data as part of
                                            the research process.
                                        </p>
                                        <p>
                                            Where authors use generative AI and
                                            AI-assisted technologies in the
                                            writing process, these technologies
                                            should only be used to improve
                                            readability and language of the
                                            work. Applying the technology should
                                            be done with human oversight and
                                            control and authors should carefully
                                            review and edit the result, because
                                            AI can generate
                                            authoritative-sounding output that
                                            can be incorrect, incomplete or
                                            biased. The authors are ultimately
                                            responsible and accountable for the
                                            contents of the work.
                                        </p>
                                        <p>
                                            Authors should disclose in their
                                            manuscript the use of AI and
                                            AI-assisted technologies and a
                                            statement will appear in the
                                            published work. Declaring the use of
                                            these technologies supports
                                            transparency and trust between
                                            authors, readers, reviewers, editors
                                            and contributors and facilitates
                                            compliance with the terms of use of
                                            the relevant tool or technology.
                                        </p>
                                        <p>
                                            Authors should not list AI and
                                            AI-assisted technologies as an
                                            author or co-author, nor cite AI as
                                            an author. Authorship implies
                                            responsibilities and tasks that can
                                            only be attributed to and performed
                                            by humans. Each (co-) author is
                                            accountable for ensuring that
                                            questions related to the accuracy or
                                            integrity of any part of the work
                                            are appropriately investigated and
                                            resolved and authorship requires the
                                            ability to approve the final version
                                            of the work and agree to its
                                            submission.
                                        </p>
                                    </li>
                                    <li>
                                        Hazards and Human or Animal Subjects
                                        <p>
                                            If the work involves chemicals,
                                            procedures or equipment that have
                                            any unusual hazards inherent in
                                            their use, the author must clearly
                                            identify these in the manuscript.
                                        </p>
                                        <p>
                                            If the work involves the use of
                                            animal or human subjects, the author
                                            should ensure that the manuscript
                                            contains a statement that all
                                            procedures were performed in
                                            compliance with relevant laws and
                                            institutional guidelines and that
                                            the appropriate institutional
                                            committee(s) have approved them.
                                            Authors should include a statement
                                            in the manuscript that informed
                                            consent was obtained for
                                            experimentation with human subjects.
                                            The privacy rights of human subjects
                                            must always be observed.
                                        </p>
                                        <p>
                                            For human subjects, the author
                                            should ensure that the work
                                            described has been carried out in
                                            accordance with The Code of Ethics
                                            of the World Medical Association
                                            (Declaration of Helsinki) for
                                            experiments involving humans.
                                        </p>
                                        <p>
                                            Appropriate consents, permissions
                                            and releases must be obtained where
                                            an author wishes to include case
                                            details or other personal
                                            information or images of patients
                                            and any other individuals. Written
                                            consents must be retained by the
                                            author and copies of the consents or
                                            evidence that such consents have
                                            been obtained must be provided to
                                            IUJAS on request .
                                        </p>
                                    </li>
                                    <li>
                                        Declaration of Competing Interests
                                        <p>
                                            All authors should disclose in their
                                            manuscript any financial and
                                            personal relationships with other
                                            people or organisations that could
                                            be viewed as inappropriately
                                            influencing (bias) their work.
                                        </p>
                                        <p>
                                            All sources of financial support for
                                            the conduct of the research and/or
                                            preparation of the article should be
                                            disclosed, as should the role of the
                                            sponsor(s), if any, in study design;
                                            in the collection, analysis and
                                            interpretation of data; in the
                                            writing of the report; and in the
                                            decision to submit the article for
                                            publication. If the funding
                                            source(s) had no such involvement
                                            then this should be stated.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
