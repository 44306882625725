import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Information } from "../interfaces/information";

@Injectable({
    providedIn: "root",
})
export class InformationService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<Information[]> {
        return this.http.get<Information[]>(
            `${environment.apiUrl}/Information/`
        );
    }

    getInformationCategoryById(id: number): Observable<Information[]> {
        return this.http.get<Information[]>(
            `${environment.apiUrl}/Information/${id}`
        );
    }
}
