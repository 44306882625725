<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4 ms-auto">
                    <ul class="top-header-login-register">
                        <li>
                            <a
                                href="https://testjournals.israa.edu.ps/auth/login"
                                ><i class="bx bx-log-in"></i> Login</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://testjournals.israa.edu.ps/auth/registration"
                                ><i class="bx bx-log-in-circle"></i> Register</a
                            >
                        </li>
                        <li>
                            <a
                                href="http://testjournals.israa.edu.ps/admin/manuscrpit"
                                ><i class="bx bx-send"></i> Submit</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-four">
        <div class="container">
            <nav
                class="navbar navbar-expand-lg navbar-light bg-light"
                [class.active]="classApplied"
            >
                <a class="navbar-brand" routerLink="/"
                    ><img
                        src="assets/img/black-logo.png"
                        width="100px"
                        height="100px"
                        alt="logo"
                /></a>
                <button
                    class="navbar-toggler"
                    type="button"
                    (click)="toggleClass()"
                >
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                                >Home</a
                            >
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                                >Journals <i class="bx bx-chevron-right"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/proceedings-series"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Proceedings Series</a
                                    >
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                                >Information <i class="bx bx-chevron-right"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/for-authors"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >For Authors</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/for-reviewers"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >For Reviewers</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/open-access-policy"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Open Access Policy</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/editorial-process"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Editorial Process</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/policy"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Policies</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/copyright"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Copyright</a
                                    >
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                                >About <i class="bx bx-chevron-right"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/about"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Overview</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/news"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >News</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/faqs"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FAQ's</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/contact"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Contact</a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input
                        type="text"
                        class="input-search"
                        placeholder="Search here..."
                    />
                    <button type="submit">
                        <i class="bx bx-search-alt"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
