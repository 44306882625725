<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-container *ngFor="let item of list">
            <ng-template carouselSlide *ngIf="item.isSlider">
                <div
                    class="main-banner"
                    [ngStyle]="{
                        'background-image':
                            'url(' + baseUrl + '/Images/' + item.image + ')'
                    }"
                >
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-content">
                                    <h1>
                                        {{ item.title }}
                                    </h1>
                                    <p>
                                        {{ item.details | truncate : 20 }}
                                    </p>
                                    <div class="btn-box">
                                        <a
                                            routerLink="/courses-2-columns-style-1"
                                            class="default-btn"
                                            ><i
                                                class="bx bx-move-horizontal icon-arrow before"
                                            ></i
                                            ><span class="label">Read More</span
                                            ><i
                                                class="bx bx-move-horizontal icon-arrow after"
                                            ></i
                                        ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> </ng-template
        ></ng-container>
    </owl-carousel-o>
</div>
