import { Component, OnInit } from "@angular/core";
import { Manuscript } from "src/app/interfaces/manuscript";
import { JournalService } from "src/app/services/journal.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-proceedings-series-page",
    templateUrl: "./proceedings-series-page.component.html",
    styleUrls: ["./proceedings-series-page.component.scss"],
})
export class ProceedingsSeriesPageComponent implements OnInit {
    baseUrl = environment.baseUrl;
    list: Manuscript[];
    constructor(private journalsService: JournalService) {}
    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.journalsService.getRecentJournals().subscribe((data) => {
            this.list = data;
            console.log(this.list);
        });
    }
}
