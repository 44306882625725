<app-header></app-header>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Question</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <div class="tabs-container">
                <div class="pane" id="tab1">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: In which Country Israa university journal
                                    of applied sciences (IUJAS) is based?
                                </h3>
                                <p><strong>A:</strong> Palestine</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Is this Journal Peer Reviewed / Fully
                                    Refereed?
                                </h3>
                                <p><strong>A:</strong> Yes</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Where do I find Online ISSN for Israa
                                    university journal of applied sciences
                                    (IUJAS)?
                                </h3>
                                <p>
                                    <strong>A:</strong> Online ISSN for Israa
                                    university journal of applied sciences
                                    (IUJAS) is ISSN: 2523-0522.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Is this Journal published at regular
                                    intervals?
                                </h3>
                                <p>
                                    <strong>A:</strong> The Israa university
                                    journal of applied sciences ( IUJAS)
                                    publishes Biannually on October and April.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Does Israa university journal of applied
                                    sciences ( IUJAS) provide DOI (Digital
                                    Object Identifier)?
                                </h3>
                                <p>
                                    <strong>A:</strong> Online DOI for Israa
                                    university journal of applied sciences
                                    (IUJAS) is https://doi.org/10.52865/NEIY7138
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What type of papers does the Israa
                                    university journal of applied sciences (
                                    IUJAS) publish?
                                </h3>
                                <p>
                                    <strong>A:</strong> Israa university journal
                                    of applied sciences (IUJAS) publish Research
                                    Paper, Survey Paper, Informative Article,
                                    Case Studies, Review Papers, Comparative
                                    Studies.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Are you open to qualitative methods?</h3>
                                <p>
                                    <strong>A:</strong> Yes, we encourage papers
                                    where mathematical models are used for
                                    analysis and conclusions.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What is the maximum accepted length of an
                                    article?
                                </h3>
                                <p>
                                    <strong>A:</strong> The Israa university
                                    journal of applied sciences ( IUJAS) has no
                                    limitation on length of the article.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How do I send my manuscript for Initial
                                    submission?
                                </h3>
                                <p>
                                    <strong>A:</strong> You can submit your
                                    manuscript through the Israa university
                                    journal of applied sciences ( IUJAS) Online
                                    Initial Submission module.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How do I send my manuscript via Email?
                                </h3>
                                <p>
                                    <strong>A:</strong> Yes, You can submit your
                                    manuscript via Email to
                                    iujas.israa@gmail.com
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How do I send my manuscript for Final
                                    submission?
                                </h3>
                                <p>
                                    <strong>A:</strong> You can submit your
                                    manuscript through the Israa university
                                    journal of applied sciences (IUJAS) Online
                                    Final Submission module.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How long does it take for an accepted
                                    paper to be actually published?
                                </h3>
                                <p>
                                    <strong>A:</strong> Approximate Publication
                                    date will be informed to you in Acceptance
                                    Email.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How will I come to know that my Paper is
                                    Online?
                                </h3>
                                <p>
                                    <strong>A:</strong> We will send an Email on
                                    your registered Email ID once your paper
                                    gets online.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is a Paper ID?</h3>
                                <p>
                                    <strong>A:</strong> Paper ID is an Unique
                                    Identification Code provided to your
                                    submitted Manuscript. You should always
                                    mention the Paper ID during any
                                    communication with us.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How long will my published paper stay
                                    online?
                                </h3>
                                <p><strong>A:</strong> Lifetime.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Is it possible to submit the same article
                                    to Israa university journal of applied
                                    sciences (IUJAS) and another Journal
                                    simultaneously?
                                </h3>
                                <p>
                                    <strong>A:</strong> No. Unless your article
                                    is not rejected by Israa university journal
                                    of applied sciences ( IUJAS) , You will be
                                    bound by an undertaking that the article
                                    submitted by you is not published or
                                    submitted for publication in any other
                                    journal than Israa university journal of
                                    applied sciences ( IUJAS) .
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: After publication, What if my article is
                                    found Plagiarized or Stolen?
                                </h3>
                                <p>
                                    <strong>A:</strong> Israa university journal
                                    of applied sciences (IUJAS) is strongly
                                    against Plagiarism. If the claim is proven,
                                    then your article will be removed from the
                                    index immediately without notice.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How much time does it take to complete
                                    the review process?
                                </h3>
                                <p>
                                    <strong>A:</strong> The peer review process
                                    usually completes within 21 days time,
                                    depending upon the number of research papers
                                    in queue.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How does the review process work?</h3>
                                <p>
                                    <strong>A:</strong> The review of articles
                                    is done through a double blind peer review.
                                    All the articles received by Israa
                                    university journal of applied sciences
                                    (IUJAS) are sent to Review Committee members
                                    after deleting the name of the Author and
                                    Country to have an unbiased opinion about
                                    the research.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Do you Print or Send Hard copies of
                                    Journal for Authors?
                                </h3>
                                <p>
                                    <strong>A:</strong> No, This is an Online
                                    Journal and does not have a Print version.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: When do I receive my acceptance
                                    Certificate?
                                </h3>
                                <p>
                                    <strong>A:</strong> After the end of the
                                    reviewing process, a certificate of
                                    acceptance will be sent via email.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: My Article is online, but i have
                                    discovered a mistake in it.
                                </h3>
                                <p>
                                    <strong>A:</strong> Corrections will be made
                                    free of charge only if requested within 3
                                    days of Online Publication.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: How can I apply to be an Israa university
                                    journal of applied sciences ( IUJAS) member?
                                </h3>
                                <p>
                                    <strong>A:</strong> You may do so by
                                    applying through the Israa university
                                    journal of applied sciences ( IUJAS) Online
                                    Application Module. Please read Eligibility
                                    details before you apply for a given
                                    position. We do not acknowledge or process
                                    applications that do not meet minimum
                                    requirements for respective positions.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: I have some important suggestions for
                                    you, how do I convey them?
                                </h3>
                                <p>
                                    <strong>A:</strong> Your feedback and
                                    suggestions are so important to us, to
                                    convey them Kindly mail us all your queries
                                    or suggestions at iujas.israa@gmail.com , We
                                    will be happy to serve you better.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
