import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-copyright-page",
    templateUrl: "./copyright-page.component.html",
    styleUrls: ["./copyright-page.component.scss"],
})
export class CopyrightPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    // for tab click event
    currentTab = "tab1";
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }
}
