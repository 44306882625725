<h3 class="widget-title">Popular Articles</h3>
<article class="item" *ngFor="let item of list | slice : 0 : 5">
    <div class="info">
        <time>{{ item.title | truncate : 3 }} </time>
        <a>{{ item.doi | truncate : 3 }} </a>
        <div class="tagcloud">
            <a
                [href]="baseUrl + '/File/' + item?.file"
                target="_blank"
                rel="noopener noreferrer"
                >DOCX</a
            >

            <a
                [href]="baseUrl + '/File/' + item?.filePdf"
                target="_blank"
                rel="noopener noreferrer"
                >PDF</a
            >
        </div>
    </div>
    <div class="clear"></div>
</article>
