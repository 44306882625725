import { Component, OnInit } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ArticleService } from "src/app/services/article.service";
import { environment } from "src/environments/environment";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-search-page",
    templateUrl: "./search-page.component.html",
    styleUrls: ["./search-page.component.scss"],
})
export class SearchPageComponent implements OnInit {
    baseUrl = environment.baseUrl;
    list: Article[] = [];
    faDownload = faDownload;
    faEye = faEye;

    constructor(
        private route: ActivatedRoute,
        private articleService: ArticleService
    ) {}

    ngOnInit(): void {
        this.getAll();
        this.route.queryParams.subscribe((params) => {
            const filter = params["Filters"];
            if (filter) {
                this.searchArticles(filter);
            }
        });
    }

    getAll() {
        this.articleService.getAll().subscribe((data) => {
            this.list = data;
        });
    }

    searchArticles(filter: string) {
        this.articleService.getFilteredArticles(filter).subscribe(
            (data: Article[]) => {
                this.list = data;
            },
            (error) => {
                console.error("Error fetching articles:", error);
            }
        );
    }
}
