<div class="courses-topbar">
    <div class="row align-items-center">
        <div class="col-lg-24 col-md-4">
            <div
                class="topbar-result-count d-flex align-items-center justify-content-center"
            >
                <p>Search for article:</p>
            </div>
        </div>

        <!-- Second Column: Search Input 1 -->
        <div class="col-lg-4 col-md-4 topbar-ordering-and-search">
            <div class="topbar-search">
                <form>
                    <label><i class="bx bx-search"></i></label>
                    <input
                        #filterInput
                        type="text"
                        class="input-search"
                        placeholder="Title / Keyword"
                    />
                </form>
            </div>
        </div>

        <!-- Third Column: Search Input 2 -->
        <!--
<div
    class="col-lg-3 col-md-3 topbar-ordering-and-search py-2 px-5"
>
    <div class="topbar-search">
        <form>
            <label><i class="bx bx-search"></i></label>
            <input
                type="text"
                class="input-search"
                placeholder="Author / Email"
            />
        </form>
    </div>
</div> 
-->

        <!-- Fourth Column: Filter Dropdown -->
        <!-- 
<div
    class="col-lg-2 col-md-3 topbar-ordering-and-search py-2 px-5"
>
    <div class="topbar-ordering">
        <select class="form-select">
            <option>All Journals</option>
            <option>IUJAS</option>
            <option>IUCAS</option>
            <option>IUBAS</option>
        </select>
    </div>
</div> 
-->

        <!-- Fifth Column: Normal Button -->
        <div
            class="col-lg-4 col-md-4 d-flex topbar-ordering-and-search justify-content-center"
        >
            <div (click)="onSearch(filterInput.value)" class="btn-box">
                <a class="default-btn"
                    ><i class="bx bx-search icon-arrow before"></i
                    ><span class="label">Search</span
                    ><i class="bx bx-search icon-arrow after"></i
                ></a>
            </div>
        </div>
    </div>
</div>
