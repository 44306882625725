import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Manuscript, ManuscriptDetails } from "../interfaces/manuscript";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class JournalService {
    constructor(private http: HttpClient) {}

    getHighlyAccessedJournals(): Observable<Manuscript[]> {
        return this.http.get<Manuscript[]>(
            `${environment.apiUrl}/Home/HighlyAccessedArticles`
        );
    }

    getRecentJournals(): Observable<Manuscript[]> {
        return this.http.get<Manuscript[]>(
            `${environment.apiUrl}/Home/RecentArticles`
        );
    }

    getJournalById(id: number): Observable<ManuscriptDetails> {
        return this.http.get<ManuscriptDetails>(
            `${environment.apiUrl}/Manuscript/GetById?id=${id}`
        );
    }
}
