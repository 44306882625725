import { Component, OnInit } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ArticleService } from "src/app/services/article.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-home-popular-articles-widget",
    templateUrl: "./home-popular-articles-widget.component.html",
    styleUrls: ["./home-popular-articles-widget.component.scss"],
})
export class HomePopularArticlesWidgetComponent implements OnInit {
    baseUrl = environment.baseUrl;
    list: Article[];
    constructor(private articleService: ArticleService) {}
    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.articleService.getAll().subscribe((data) => {
            this.list = data;
            console.log(this.list);
        });
    }
}
