import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-editorials-page",
    templateUrl: "./editorials-page.component.html",
    styleUrls: ["./editorials-page.component.scss"],
})
export class EditorialsPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
