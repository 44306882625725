import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "truncate",
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit: number = 10): string {
        if (!value) return "";
        let words = value.split(/\s+/);
        if (words.length > limit) {
            return words.slice(0, limit).join(" ") + "...";
        } else {
            return words.join(" ");
        }
    }
}
