import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Article } from "../interfaces/article";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ArticleService {
    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get<Article[]>(
            `${environment.apiUrl}/Home/HighlyAccessedArticles`
        );
    }

    getFilteredArticles(filter: string): Observable<Article[]> {
        const url = `${
            environment.apiUrl
        }/Home/RecentArticles?Filters=${encodeURIComponent(filter)}`;
        return this.http.get<Article[]>(url);
    }
}
