import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Manuscript, ManuscriptDetails } from "src/app/interfaces/manuscript";
import { JournalService } from "src/app/services/journal.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-journals-details-page",
    templateUrl: "./journals-details-page.component.html",
    styleUrls: ["./journals-details-page.component.scss"],
})
export class JournalsDetailsPageComponent implements OnInit {
    baseUrl = environment.baseUrl;
    manuscriptDetails: ManuscriptDetails | null = null;
    tags: string[];
    id: number;
    constructor(
        private journalService: JournalService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService
    ) {
        this.id = Number(this.route.snapshot.paramMap.get("id"));
    }

    ngOnInit(): void {
        this.spinner.show();
        this.getManuscriptDetails();
    }

    getManuscriptDetails(): void {
        this.journalService.getJournalById(this.id).subscribe(
            (data: ManuscriptDetails) => {
                this.manuscriptDetails = data;
                if (this.manuscriptDetails.manuscript.keyword) {
                    this.tags = this.manuscriptDetails.manuscript.keyword
                        .split(";")
                        .map((tag) => tag.trim());
                }
                this.spinner.hide(); // Hide the spinner once data is loaded
            },
            (error) => {
                console.error("Error fetching manuscript details", error);
                this.spinner.hide(); // Hide the spinner even if there's an error
            }
        );
    }
}
