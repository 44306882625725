import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
})
export class SearchComponent {
    constructor(private router: Router) {}

    onSearch(filterValue: string) {
        this.router.navigate(["/results"], {
            queryParams: { Filters: `title@=${filterValue}` },
        });
    }
}
