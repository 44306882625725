<app-header></app-header>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Editorial Process</li>
            </ul>
            <h2>Editorial Process</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <div class="tabs-container">
                <div class="pane">
                    <div class="row">
                        <h3>
                            An overview of the peer-review process for The Israa
                            University Journal of Applied Sciences (IUJAS):
                        </h3>
                    </div>
                    <div class="row pt-100">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Submission</h3>
                                <p>
                                    Authors submit their manuscripts to IUJAS
                                    through an online submission system.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Editorial Board Member Review</h3>
                                <p>
                                    Upon submission, the editorial team of IUJAS
                                    performs an initial review. They assess the
                                    manuscript's compliance with formatting and
                                    guidelines, as well as its alignment with
                                    the journal's scope and aim and check the
                                    plagiarism.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Assign the Reviewers</h3>
                                <p>
                                    Upon submission, the editorial team of IUJAS
                                    performs an initial review. They assess the
                                    manuscript's compliance with formatting and
                                    guidelines, as well as its alignment with
                                    the journal's scope and aim and check the
                                    plagiarism.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Peer Review</h3>
                                <p>
                                    Reviewers critically assess the manuscript's
                                    content, methodology, validity of results,
                                    significance, and overall quality. They
                                    provide detailed feedback, suggestions for
                                    improvement, and recommendations on whether
                                    the manuscript should be accepted, revised,
                                    or rejected.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Author Revision</h3>
                                <p>
                                    If the manuscript requires revisions,
                                    authors address the reviewers' comments and
                                    suggestions. They modify the manuscript
                                    accordingly and provide a detailed response
                                    to each comment.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Second Review (If Necessary)</h3>
                                <p>
                                    In some cases, reviewers may request a
                                    second review of the revised manuscript to
                                    ensure that the recommended changes have
                                    been adequately addressed.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Editorial Board Member Decision</h3>
                                <p>
                                    Based on the reviewers' feedback and the
                                    revised manuscript, the Editorial Board
                                    Member makes an editorial decision. The
                                    decision options typically include "Accept,"
                                    "Minor Revisions," "Major Revisions," or
                                    "Reject."
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Notification to Authors</h3>
                                <p>
                                    Authors are informed of the Editorial Board
                                    Member decision, along with the reviewers'
                                    comments and suggestions. If revisions are
                                    required, authors are provided with specific
                                    guidance for further improvement.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Final Approval</h3>
                                <p>
                                    Once the revised manuscript meets the IUJAS
                                    standards and the reviewers are satisfied,
                                    the manuscript is accepted for publication.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Publication</h3>
                                <p>
                                    The accepted manuscript undergoes final
                                    formatting and is published in the upcoming
                                    issue of IUJAS. It becomes accessible to the
                                    readership and the wider academic community.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <img
                            src="\assets\img\publish\publish flow.jpg"
                            alt="Publish Flow"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
