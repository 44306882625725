<app-header /> <app-home-main-banner />

<div class="courses-area ptb-100">
    <div class="container-fluid">
        <!-- First Column: Showing Results Count -->
        <app-search />
        <div class="row">
            <div class="col-lg-2 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_ednuv_posts_thumb">
                        <article class="item d-flex align-items-center">
                            <a routerLink="/single-courses" class="thumb">
                                <span
                                    class="fullimage cover"
                                    role="img"
                                    style="
                                        background-image: url(../../../../assets/img/logo.png);
                                    "
                                ></span>
                            </a>
                            <div
                                class="col-lg-2 col-md-1 d-flex topbar-ordering-and-search"
                            >
                                <div class="btn-box">
                                    <a
                                        href="https://testjournals.israa.edu.ps/admin/manuscrpit"
                                        class="default-btn"
                                        ><i
                                            class="bx bx-send icon-arrow before"
                                        ></i
                                        ><span class="label">Submit</span
                                        ><i
                                            class="bx bx-send icon-arrow after"
                                        ></i
                                    ></a>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <app-home-popular-articles-widget />
                    </div>
                </aside>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <span>Welcome to IUJAS</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >IUJAS Journals</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="instructor-area pt-50 pb-10">
                            <div class="container">
                                <div class="section-title">
                                    <h3>Recent Articles</h3>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngFor="let item of list"
                            class="single-courses-list-box mb-30"
                        >
                            <div class="box-item">
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <span>{{ item.authorName }}</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >{{ item.title }}</a
                                            >
                                        </h3>
                                        <p>
                                            {{ item.abstract | truncate : 50 }}
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <fa-icon
                                                    [icon]="faDownload"
                                                ></fa-icon>
                                                {{ item.dawonlod }} Downloads
                                            </li>
                                            <li class="courses-lesson">
                                                <fa-icon
                                                    [icon]="faEye"
                                                ></fa-icon>
                                                {{ item.watch }} Views
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <span
                                class="page-numbers current"
                                aria-current="page"
                                >1</span
                            >
                            <a routerLink="" class="page-numbers">2</a>
                            <a routerLink="" class="page-numbers">3</a>
                            <a routerLink="" class="page-numbers">4</a>
                            <a routerLink="" class="page-numbers">5</a>
                            <a routerLink="" class="next page-numbers"
                                ><i class="bx bx-chevron-right"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_ednuv_posts_thumb">
                        <article class="item d-flex align-items-center">
                            <a routerLink="" class="thumb">
                                <span
                                    class="fullimage cover"
                                    role="img"
                                    style="
                                        background-image: url(../../../../assets/img/scopus.png);
                                    "
                                ></span>
                            </a>
                            <a routerLink="" class="thumb">
                                <span
                                    class="fullimage cover"
                                    role="img"
                                    style="
                                        background-image: url(../../../../assets/img/scopus.png);
                                    "
                                ></span>
                            </a>

                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <app-home-news-widget />
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
