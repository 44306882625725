<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>My Dashboard</li>
            </ul>
            <h2>My Dashboard</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <img
                            src="assets/img/instructor/instructor1.jpg"
                            alt="image"
                        />
                    </div>
                </div>
                <div class="col-lg-8 col-md-7">
                    <div class="profile-content">
                        <h3>James Anderson</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Labore atque officiis maxime suscipit expedita
                            obcaecati nulla in ducimus.
                        </p>
                        <ul class="contact-info">
                            <li>
                                <i class="bx bx-envelope"></i>
                                <a href="mailto:hello@jamesanderson.com"
                                    >hellojamesanderson.com</a
                                >
                            </li>
                            <li>
                                <i class="bx bx-phone"></i>
                                <a href="tel:+502464674">+502 464 674</a>
                            </li>
                            <li>
                                <i class="bx bx-world"></i>
                                <a href="#" target="_blank"
                                    >www.jamesanderson.com</a
                                >
                            </li>
                        </ul>
                        <ul class="social">
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-pinterest-alt"></i
                                ></a>
                            </li>
                        </ul>
                        <a routerLink="/" class="myDashboard-logout">Logout</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="myDashboard-navigation">
            <ul>
                <li>
                    <a routerLink="/my-dashboard" class="active"
                        ><i class="bx bxs-dashboard"></i> Dashboard</a
                    >
                </li>
                <li>
                    <a routerLink="/orders"
                        ><i class="bx bx-cart"></i> Orders</a
                    >
                </li>
                <li>
                    <a routerLink="/downloads"
                        ><i class="bx bx-download"></i> Downloads</a
                    >
                </li>
                <li>
                    <a routerLink="/edit-address"
                        ><i class="bx bx-home-alt"></i> Addresses</a
                    >
                </li>
                <li>
                    <a routerLink="/edit-account"
                        ><i class="bx bx-edit"></i> Account Details</a
                    >
                </li>
                <li>
                    <a routerLink="/"><i class="bx bx-log-out"></i> Logout</a>
                </li>
            </ul>
        </div>
        <div class="myDashboard-content">
            <p>
                Hello <strong>James Anderson</strong> (not
                <strong>James Anderson</strong>?
                <a routerLink="/my-dashboard">Log out</a>)
            </p>
            <p>
                From your account dashboard you can view your
                <a routerLink="/orders">recent orders</a>, manage your
                <a routerLink="/edit-address">shipping and billing addresses</a
                >, <a routerLink="/edit-account">edit your password</a>, and
                <a routerLink="/edit-account">account details</a>.
            </p>
            <h3>Recent Orders</h3>
            <div class="recent-orders-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>#074145O5</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td>
                                <a
                                    routerLink="/single-courses"
                                    class="view-button"
                                    >View</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>#074145O6</td>
                            <td>October 18, 2019</td>
                            <td>On Hold</td>
                            <td>$49.00 for 1 item</td>
                            <td>
                                <a
                                    routerLink="/single-courses"
                                    class="view-button"
                                    >View</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>#074145O7</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td>
                                <a
                                    routerLink="/single-courses"
                                    class="view-button"
                                    >View</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>#074145O5</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td>
                                <a
                                    routerLink="/single-courses"
                                    class="view-button"
                                    >View</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>#074145O6</td>
                            <td>October 18, 2019</td>
                            <td>On Hold</td>
                            <td>$49.00 for 1 item</td>
                            <td>
                                <a
                                    routerLink="/single-courses"
                                    class="view-button"
                                    >View</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>#074145O7</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td>
                                <a
                                    routerLink="/single-courses"
                                    class="view-button"
                                    >View</a
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
