<app-header></app-header>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Editorials Board</li>
            </ul>
            <h2>Team of Editorials</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <h3>Editor-in-Chief</h3>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor1.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Abdel Fattah A. Qaraman</a
                            >
                        </h3>
                        <span>Israa University-Gaza, Palestine</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <h3>Associate Editors</h3>
                <h4>Co-Editor</h4>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor4.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Maher A. Elbayoumi</a
                                >
                            </h3>
                            <span>Israa University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor5.jpg"
                                alt="images"
                            />
                        </div>

                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Ahmed M. ALmassri</a
                                >
                            </h3>
                            <span
                                >Kyushu Institute of Technology (Kyutech),
                                Japan</span
                            >
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Ahmed H. Albelbeisi</a
                                >
                            </h3>
                            <span>Israa University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <h4>Editorial Advisory Board</h4>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor4.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Hikmat Hilal</a
                                >
                            </h3>
                            <span>An - Najah University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor5.jpg"
                                alt="images"
                            />
                        </div>

                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Netham Al - Ashqar</a
                                >
                            </h3>
                            <span>Islamic University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Hazem Abu Shawish</a
                                >
                            </h3>
                            <span>Al - Aqsa University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Helmi Salem</a
                                >
                            </h3>
                            <span>SDRI � Bethlehem, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Nahed El Laham</a
                                >
                            </h3>
                            <span>Al-Azhar University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Hala Al-Khazindar</a
                                >
                            </h3>
                            <span>Islamic University-Gaza, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Ashraf Al-Jedi</a
                                >
                            </h3>
                            <span>Islamic University-Gaza, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Abdel Fattah Abd Rabou</a
                                >
                            </h3>
                            <span>Islamic University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Mohammed Shabat</a
                                >
                            </h3>
                            <span>Islamic University-Gaza, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Ezzat Al - Askari</a
                                >
                            </h3>
                            <span>Israa University-Gaza, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Taleb Abu Moala</a
                                >
                            </h3>
                            <span>Israa University-Gaza, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Suheir M. Harb
                                </a>
                            </h3>
                            <span>Palestine Technical College</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Fadel Abu Hain</a
                                >
                            </h3>
                            <span>Al - Aqsa University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Nabil Al - Eilah</a
                                >
                            </h3>
                            <span>Al - Aqsa University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Rifa Jarallah El-Khozondar
                                </a>
                            </h3>
                            <span>Al-Aqsa University , Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Jihad Adwan</a
                                >
                            </h3>
                            <span>University of Manchester, UK</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Akram Abu Eisha</a
                                >
                            </h3>
                            <span>Harford University, USA</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Ahmed Sulibi
                                </a>
                            </h3>
                            <span>Israa University, Palestine</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Ahmad Harb</a
                                >
                            </h3>
                            <span>Jordan-German University � Jordan</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Prof. Mohammed Ahmed Hamdan</a
                                >
                            </h3>
                            <span>University of Jordan, Jordan</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Mohammed Bashir
                                </a>
                            </h3>
                            <span
                                >Universiti Tunku Abdul Rahman � Malaysia</span
                            >
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Bassam Tawabini</a
                                >
                            </h3>
                            <span>(KFUPM) - Saudi Arabia</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Pedro A. Garcia</a
                                >
                            </h3>
                            <span>University of Granada � Spain</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Jamil Abdul Ati</a
                                >
                            </h3>
                            <span>Saybrok University � California � USA</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Dr. Raed Lubbad</a
                                >
                            </h3>
                            <span
                                >Norwegian University of Science and Technology,
                                Norway</span
                            >
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <h4>Managing Editor</h4>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <img
                                src="assets/img/instructor/instructor6.jpg"
                                alt="images"
                            />
                        </div>
                        <div class="member-content">
                            <h3>
                                <a routerLink="/single-instructor"
                                    >Ms. Bessaan Al Mudalal</a
                                >
                            </h3>
                            <span>Managing Editor</span>
                            <ul class="social">
                                <li>
                                    <a href="#" class="facebook" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        class="instagram"
                                        target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
