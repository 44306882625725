<app-header></app-header>

<div class="page-title-area" *ngIf="editorial">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>{{ editorial.name }}</li>
            </ul>
            <h2>{{ editorial.name }}</h2>
        </div>
    </div>
</div>

<div class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img
                            [src]="editorial.image"
                            alt="{{ editorial.name }}"
                        />
                        <ul class="social-link">
                            <li>
                                <a
                                    href="#"
                                    class="d-block facebook"
                                    target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    class="d-block twitter"
                                    target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    class="d-block instagram"
                                    target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    class="d-block linkedin"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    class="d-block pinterest"
                                    target="_blank"
                                    ><i class="bx bxl-pinterest-alt"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>{{ editorial.name }}</h3>
                        <p>
                            {{ editorial.bio }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
