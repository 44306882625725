<app-header></app-header>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img
                        src="assets/img/about/about1.jpg"
                        class="shadow"
                        alt="image"
                    />
                    <img
                        src="assets/img/about/about2.jpg"
                        class="shadow"
                        alt="image"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Journal Insights</h2>
                    <p>
                        <strong>ISSN:</strong> Online ISSN: 2523-0522. <br />
                        <strong>DOI: </strong
                        ><a href="https://doi.org/10.52865/NEIY7138">
                            https://doi.org/10.52865/NEIY7138</a
                        >
                    </p>
                    <h5>Abstracting and indexing</h5>
                    <p>
                        Scopus:
                        <a href="https://www.scopus.com/sourceid/21101066734"
                            >https://www.scopus.com/sourceid/21101066734</a
                        >
                        <br />
                        Open-access database, OAJI:
                        <a
                            href="https://oaji.net/journal-detail.html?number=11839"
                            >https://oaji.net/journal-detail.html?number=11839</a
                        >
                        <br />
                        The VU Journal Publishing Guide:
                        <a
                            href="https://journalpublishingguide.vu.nl/WebQuery/vubrowser/87964"
                            >Israa University Journal of Applied Science
                            (vu.nl)</a
                        >
                        <br />
                        ROAD:
                        <a
                            href="https://portal.issn.org/resource/ISSN/2523-0522"
                            >https://portal.issn.org/resource/ISSN/2523-0522</a
                        >
                    </p>
                    <div class="features-text">
                        <h5><i class="bx bx-planet"></i>Subject areas</h5>
                        <p>
                            <strong>IUJAS</strong> journal covers all applied
                            science subjects such as Health, Environment,
                            Engineering, Pure Science ...etc
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Time to First Decision</h3>
                        <p>
                            The average number of weeks it takes for an article
                            to go from manuscript submission to the initial
                            decision on the article, including standard and desk
                            rejects: <strong>3 weeks</strong>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Review Time</h3>
                        <p>
                            The average number of weeks it takes for an article
                            to go through the editorial review process for this
                            journal, including standard and desk rejects:
                            <strong>6 weeks</strong>
                        </p>
                    </div>
                </div>
                <div
                    class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
                >
                    <div class="about-text">
                        <h3>Publication Time</h3>
                        <p>
                            The average number of weeks it takes to reach from
                            manuscript acceptance to the first appearance of the
                            article online
                            <strong>(with DOI).: 11 weeks</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="story-area ptb-100">
    <app-aim-and-scope></app-aim-and-scope>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <app-editorial-board></app-editorial-board>
    </div>
</div>

<div class="container pb-100">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="faq-item">
                <h3>Select Editor for the Journal</h3>
                <p>
                    IUJAS is recruiting Editorial Board Members. As IUJAS is
                    growing, we must ensure that the journal is able to cope
                    with these increased submissions whilst maintaining our high
                    editorial standards. Therefore, we are looking to expand our
                    editorial team. Israa University Journal of Applied Sciences
                    (IUJAS) is open access, peer-reviewed scholarly publication
                    that covers a varied range of applied sciences, Engineering,
                    Medical, and Technology.
                </p>
                <h4>Criteria</h4>
                <p>
                    We are interested in individuals who are committed to open
                    access, data sharing, and innovation in scientific
                    publishing.
                </p>
                <h4>Suitable candidates should:</h4>
                <ul>
                    <li>
                        Look for individuals with substantial expertise in the
                        field of applied sciences, engineering, medical
                        sciences, or technology, which aligns with the journal's
                        scope.
                    </li>
                    <li>
                        Have a minimum of ten (10) research papers published in
                        reputable, English language, peer-reviewed journals; be
                        frequently listed as a corresponding author during the
                        last five years; have demonstrable expertise that fits
                        within the scope and the needs of the journal.
                    </li>
                    <li>
                        Prior editorial experience, such as serving as an
                        associate editor, guest editor, or editorial board
                        member for reputable journals, can be valuable.
                    </li>
                </ul>
                <h4>Suitable Editorial Board Member for IUJAS also must:</h4>
                <p>
                    Support the journal's scope, editorial standards and
                    policies, and be a committed advocate who will represent
                    IUJAS within the scientific community; provide a
                    professional and supportive service to our authors and
                    ensure that the manuscripts for which they are responsible
                    fulfil the requirements of the Journal and are handled
                    efficiently and appropriately through peer-review.
                </p>
                <h4>Role and responsibilities</h4>
                <p>
                    The primary responsibility of our Editorial Board Members is
                    to manage the peer review process. Editorial Board Members
                    you are responsible for the scientific quality of the
                    manuscripts that they handle. They adhere to the editorial
                    standards, policies and scope of the Journal and ensure that
                    manuscripts are handled efficiently and appropriately.
                </p>
                <p>
                    An Editorial Board Member for IUJAS should be engaged and
                    willing to handle a minimum of ten (10) manuscripts per year
                    across a range of disciplines within their broader field of
                    expertise.
                </p>
            </div>
        </div>
    </div>
</div>
