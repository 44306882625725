<app-header></app-header>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Copyright</li>
            </ul>
            <h2>Copyright</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <ul class="nav-tabset">
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab1' }"
                >
                    <span class="active" (click)="switchTab($event, 'tab1')"
                        >Copytright</span
                    >
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab2' }"
                >
                    <span (click)="switchTab($event, 'tab2')"
                        >Copyright Form</span
                    >
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Copyright and Open access licenses</h3>
                                <p>
                                    In order for IUJAS to publish and
                                    disseminate research articles, we need
                                    certain publishing rights from authors
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Authors rights</h3>
                                <p>
                                    Authors must agree that published article to
                                    be distributed under the terms of the
                                    Creative Commons Attribution License CC BY
                                    NC ND 4.0, which permits reuse for
                                    non-commercial purpose as far as you give
                                    appropriate credit, provide a link to the
                                    license, and indicate if changes were made.
                                    You may do so in any reasonable manner, but
                                    not in any way that suggests the licensor
                                    endorses you or your use.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>Author rights are the following:</h3>
                                <ol>
                                    <li>Retain patent and trademark rights</li>
                                    <li>
                                        Retain the rights to use their research
                                        data freely without any restriction
                                    </li>
                                    <li>
                                        Receive proper attribution and credit
                                        for their published work
                                    </li>
                                    <li>
                                        Re-use their own material in new works
                                        without permission or payment (with full
                                        acknowledgement of the original
                                        article):
                                        <ul>
                                            <li>
                                                Extend an article to book length
                                            </li>
                                            <li>
                                                Include an article in a
                                                subsequent compilation of their
                                                own work
                                            </li>
                                            <li>
                                                Re-use portions, excerpts, and
                                                their own figures or tables in
                                                other works.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Use and share their works for scholarly
                                        purposes (with full acknowledgement of
                                        the original article):
                                        <ul>
                                            <li>
                                                In their own classroom teaching.
                                                Electronic and physical
                                                distribution of copies is
                                                permitted
                                            </li>
                                            <li>
                                                If an author is speaking at a
                                                conference, they can present the
                                                article and distribute copies to
                                                the attendees
                                            </li>
                                            <li>
                                                Distribute the article,
                                                including by email, to their
                                                students and to research
                                                colleagues who they know for
                                                their personal use
                                            </li>
                                            <li>
                                                Include in a thesis or
                                                dissertation (provided this is
                                                not published commercially)
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Publicly share the accepted manuscript
                                        on non-commercial sites(using a CC
                                        BY-NC-ND license ). This is easy to do.
                                        On your accepted manuscript add the
                                        following : For example:
                                        <p>
                                            &copy; year. This manuscript version
                                            is made available under the
                                            CC-BY-NC-ND 4.0 license
                                            https://creativecommons.org/licenses/by-nc-nd/4.0/
                                        </p>
                                    </li>
                                    <li>Retain copyright</li>
                                </ol>
                                <h3>Institution rights</h3>
                                <p>
                                    Regardless of how the author chooses to
                                    publish with Elsevier, their institution has
                                    the right to use articles for classroom
                                    teaching and internal training. Articles can
                                    be used for these purposes throughout the
                                    author's institution, not just by the
                                    author:
                                </p>
                                <table>
                                    <th>
                                        Institution rights in IUJAS's
                                        proprietary journal (providing full
                                        acknowledgement of the original article
                                        is given)
                                    </th>
                                    <th>All articles</th>
                                    <tr>
                                        <td>
                                            Copies can be distributed
                                            electronically as well as in
                                            physical form for classroom teaching
                                            and internal training purposes
                                        </td>
                                        <td>&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Material can be included in
                                            coursework and courseware programs
                                            for use within the institution (but
                                            not in Massive Open Online Courses)
                                        </td>
                                        <td>&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Articles can be included in
                                            applications for grant funding
                                        </td>
                                        <td>&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Theses and dissertations which
                                            contain embedded final published
                                            articles as part of the formal
                                            submission can be posted publicly by
                                            the awarding institution with DOI
                                            links back to the formal publication
                                            on IUJAS
                                        </td>
                                        <td>&#10003;</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>
                                    Israa University Journal Of Applied Science
                                    (IUJAS)
                                </h3>
                                <p>
                                    The IUJAS and authors share a common
                                    interest in the protection of copyright:
                                    authors principally because they want their
                                    creative works to be protected from
                                    plagiarism and other unlawful uses, The
                                    IUJAS because we need to protect our work
                                    and investment in the production, marketing
                                    and distribution of the article written by
                                    the author. In order to do so effectively,
                                    The IUJAS request a formal written transfer
                                    of copyright from the author(s) for each
                                    article published. IUJAS and authors are
                                    also concerned that the integrity of an
                                    article (once refereed and accepted for
                                    publication) be maintained, and in order to
                                    protect that reference value and validation
                                    process, we ask that authors recognize that
                                    distribution (including through the
                                    Internet/WWW or other on-line means) of the
                                    version of the article as accepted for
                                    publication is best administered by the
                                    IUJAS Please complete and return this form
                                    promptly.
                                </p>
                                <p>Manuscript No. :</p>
                                <p>Article entitled :</p>
                                <p>Corresponding author :</p>
                                <p>Authors :</p>
                                <p>
                                    To be published in:
                                    <strong
                                        >Israa University Journal Of Applied
                                        Science (IUJAS)</strong
                                    >
                                </p>
                                <p>
                                    Effective upon acceptance for publication,
                                    copyright (including all rights there under
                                    and including the right to authorize
                                    photocopying and reproduction in all media,
                                    whether separately or as a part of a journal
                                    issue or otherwise) in the above article and
                                    any modifications of it by the author(s) is
                                    hereby transferred throughout the world and
                                    for the full term and all extensions and
                                    renewals thereof, to:
                                </p>
                                <strong
                                    >Israa University Journal Of Applied Science
                                    (IUJAS)</strong
                                >
                                <p>
                                    This transfer includes the right to adapt
                                    the presentation of the article for use in
                                    conjunction with computer systems and
                                    programs, including reproduction or
                                    publication in machine-readable form and
                                    incorporation in retrieval systems.
                                </p>
                                <h3>Rights of authors</h3>
                                <p>
                                    The following rights are retained by the
                                    author(s):
                                </p>
                                <ol>
                                    <li>
                                        Patent and trademark rights and rights
                                        to any process or procedure described in
                                        the article.
                                    </li>
                                    <li>
                                        The right to photocopy or make single
                                        electronic copies of the article for
                                        their own personal use, including for
                                        their own classroom use, or for the
                                        personal use of colleagues, provided the
                                        copies are not offered for sale and are
                                        not distributed in a systematic way
                                        outside of their employing institution
                                        (e.g., via an e-mail list or public file
                                        server). Posting of a preprint version
                                        of this work on an electronic public
                                        server is permitted. Posting of the
                                        published article on a secure network
                                        (not accessible to the public) within
                                        the author's institution is permitted.
                                        However, posting of the published
                                        article on an electronic public server
                                        can only be done with IUJAS written
                                        permission.
                                    </li>
                                    <li>
                                        The right, subsequent to publication, to
                                        use the article or any part thereof free
                                        of charge in a printed compilation of
                                        works of their own, such as collected
                                        writings or lecture notes, in a thesis,
                                        or to expand the article into
                                        book-length form for publication.
                                    </li>
                                </ol>
                                <p>
                                    All copies, paper, electronic or other use
                                    of the information must include an
                                    indication of the copyright ownership and a
                                    full citation of the journal source. Please
                                    refer requests for all uses not included
                                    above, including the authorization of third
                                    parties to reproduce or otherwise use all or
                                    part of the article (including figures and
                                    tables), to: Israa University Journal Of
                                    Applied Science ( IUJAS), e-mail :
                                    iujas.israa@gmail.com
                                </p>
                                <h3>Authorship</h3>
                                <p>
                                    If the article was prepared jointly with
                                    other author(s), the signing author has
                                    informed the co-author(s) of the terms of
                                    this copyright transfer and is signing on
                                    their behalf as their agent and represents
                                    that he or she is authorized to do so.
                                    Please confirm by marking the appropriate
                                    box following the signature line. The
                                    signing author shall bear the responsibility
                                    for designating the co-author(s) and must
                                    inform IUJAS of any changes in authorship.
                                    If copyright is held by the employer, the
                                    employer or an authorized representative of
                                    the employer must sign. If the author signs,
                                    it is understood that this is with the
                                    authorization of the employer and the
                                    employer's acceptance of the terms of the
                                    transfer. Please confirm by marking the
                                    appropriate box following the signature
                                    line.
                                </p>
                                <h3>Warranties</h3>
                                <p>
                                    The author(s) warrant(s) that the article is
                                    the author's original work and has not been
                                    published before. The author(s) warrant(s)
                                    that the article contains no libelous or
                                    other unlawful statements, and does not
                                    infringe on the rights of others. If
                                    excerpts from copyrighted works are
                                    included, the author(s) has (have) obtained
                                    or will obtain written permission from the
                                    copyright owners and will credit the sources
                                    in the article.
                                </p>
                                <p>Manuscript No. :</p>
                                <p>Article entitled :</p>
                                <p>Signature of copyright owner(s) :</p>
                                <p>Name (printed) :</p>
                                <p>Title (if employer representative) :</p>
                                <p>Company or institution :</p>
                                <p>Date :</p>
                                <p>
                                    Please sign this form in ink, retain a copy
                                    of this form for your file A scanned pdf or
                                    Xerox copy of the form by e-mail or fax is
                                    sufficient for us to proceed in good faith.
                                    E-mail: iujas.israa@gmail.com
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
