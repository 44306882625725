import { Component, OnInit } from "@angular/core";
import { Information } from "src/app/interfaces/information";
import { informationCategory } from "src/app/interfaces/information-category";
import { InformationCategoryService } from "src/app/services/information-category.service";
import { InformationService } from "src/app/services/information.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-informations-page",
    templateUrl: "./informations-page.component.html",
    styleUrls: ["./informations-page.component.scss"],
})
export class InformationsPageComponent implements OnInit {
    baseUrl = environment.baseUrl;
    information: Information[];
    informationCategory: informationCategory[];
    currentTab: number;
    filteredInformation: any;
    constructor(
        private informationCategoryService: InformationCategoryService,
        private informationService: InformationService
    ) {}

    ngOnInit(): void {
        this.getAllInformationCategories();
        this.getAllInformations();
    }

    getAllInformationCategories() {
        this.informationCategoryService.getAll().subscribe((informationCategoryData) => {
            this.informationCategory = informationCategoryData;
            if (this.informationCategory.length > 0) {
                this.currentTab = this.informationCategory[0].id; // Initialize with the first tab
                this.filterInformation();
            }
        });  
    }

    getAllInformations() {
        this.informationService.getAll().subscribe((informationData) => {
            this.information = informationData;
            this.filterInformation();
        });
    }

    switchTab(event: Event, tabId: number) {
        this.currentTab = tabId;
        this.filterInformation();
    }

    filterInformation() {
        if (this.currentTab) {
            this.filteredInformation = this.information.filter(
                (info) => info.informationCategoryId === this.currentTab
            );
        }
    }
}

