<app-header />

<div
    class="page-title-area"
    [style.background-image]="'url(' + baseUrl + '/images/' + item.image + ')'"
>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="/news">News</a></li>
                <li>{{ item.title }}</li>
            </ul>
            <h2>{{ item.title }}</h2>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img
                            [src]="baseUrl + '/images/' + item.image"
                            alt="image"
                        />
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class="bx bx-folder-open"></i>
                                    <span>Journal</span>
                                    <a routerLink="/single-blog">{{
                                        item.journal.name
                                    }}</a>
                                </li>
                                <li>
                                    <i class="bx bx-calendar"></i>
                                    <span>Last Updated</span>
                                    <a routerLink="/single-blog">01/14/2019</a>
                                </li>
                            </ul>
                        </div>
                        <h3>
                            {{ item.title }}
                        </h3>
                        <p>
                            {{ item.details }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <!-- <aside class="widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text"
                                    >Search for:</span
                                >
                                <input
                                    type="search"
                                    class="search-field"
                                    placeholder="Search..."
                                />
                            </label>
                            <button type="submit">
                                <i class="bx bx-search-alt"></i>
                            </button>
                        </form>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span
                                    class="fullimage cover bg1"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 10, 2019</time>
                                <h4 class="title usmall">
                                    <a routerLink="/single-blog"
                                        >Making Peace With The Feast Or Famine
                                        Of Freelancing</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span
                                    class="fullimage cover bg2"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 21, 2019</time>
                                <h4 class="title usmall">
                                    <a routerLink="/single-blog"
                                        >I Used The Web For A Day On A 50 MB
                                        Budget</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span
                                    class="fullimage cover bg3"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall">
                                    <a routerLink="/single-blog"
                                        >How To Create A Responsive Popup
                                        Gallery?</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Design
                                    <span class="post-count">(03)</span></a
                                >
                            </li>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Lifestyle
                                    <span class="post-count">(05)</span></a
                                >
                            </li>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Script
                                    <span class="post-count">(10)</span></a
                                >
                            </li>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Device
                                    <span class="post-count">(08)</span></a
                                >
                            </li>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Tips
                                    <span class="post-count">(01)</span></a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Ednuv Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-style-2"
                                >IT <span class="tag-link-count"> (3)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Raque
                                <span class="tag-link-count"> (3)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Games
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Fashion
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Travel
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Smart
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Marketing
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Tips
                                <span class="tag-link-count"> (2)</span></a
                            >
                        </div>
                    </div>
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class="bx bx-phone-call"></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div>
                </aside> -->
            </div>
        </div>
    </div>
</div>
