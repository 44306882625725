<h3 class="widget-title">News</h3>
<article *ngFor="let item of list | slice : 0 : 4" class="item">
    <a routerLink="/news/{{ item.id }}" class="thumb">
        <span
            class="fullimage cover"
            [ngStyle]="{
                'background-image':
                    'url(' + baseUrl + '/Images/' + item.image + ')'
            }"
            role="img"
        ></span>
    </a>
    <div class="info">
        <time>{{ item.title }}</time>
        <h4 class="title usmall">
            <a routerLink="/single-courses">{{
                item.details | truncate : 5
            }}</a>
        </h4>
    </div>
    <div class="clear"></div>
</article>
