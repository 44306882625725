<app-header />

<div class="courses-area ptb-100">
    <div class="container">
        <app-search />
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div
                            *ngFor="let item of list"
                            class="single-courses-list-box mb-30"
                        >
                            <div class="box-item">
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <span>{{ item.authorName }}</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >{{ item.title }}</a
                                            >
                                        </h3>
                                        <p>
                                            {{ item.abstract | truncate : 50 }}
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <fa-icon
                                                    [icon]="faDownload"
                                                ></fa-icon>
                                                {{ item.dawonlod }} Downloads
                                            </li>
                                            <li class="courses-lesson">
                                                <fa-icon
                                                    [icon]="faEye"
                                                ></fa-icon>
                                                {{ item.watch }} Views
                                            </li>
                                            <li class="courses-price">Free</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <span
                                class="page-numbers current"
                                aria-current="page"
                                >1</span
                            >
                            <a routerLink="/courses-list" class="page-numbers"
                                >2</a
                            >
                            <a routerLink="/courses-list" class="page-numbers"
                                >3</a
                            >
                            <a routerLink="/courses-list" class="page-numbers"
                                >4</a
                            >
                            <a routerLink="/courses-list" class="page-numbers"
                                >5</a
                            >
                            <a
                                routerLink="/courses-list"
                                class="next page-numbers"
                                ><i class="bx bx-chevron-right"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
