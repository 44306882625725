import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { News } from "src/app/interfaces/news";
import { NewsService } from "src/app/services/news.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-home-main-banner",
    templateUrl: "./home-main-banner.component.html",
    styleUrls: ["./home-main-banner.component.scss"],
})
export class HomeMainBannerComponent implements OnInit {
    list: News[];
    baseUrl = environment.baseUrl;
    constructor(private newsService: NewsService) {}

    ngOnInit(): void {
        this.getAll();
    }

    homeSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        autoHeight: true,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>",
        ],
    };

    getAll() {
        this.newsService.getAll().subscribe((data) => {
            this.list = data;
            console.log(this.list);
        });
    }
}
