<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-start">
                <h2>IUJAS AIM AND SCOPE</h2>
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="story-content">
                <p>
                    The Israa University Journal of Applied Sciences (IUJAS) is
                    open access, peer-reviewed scholarly publication that covers
                    a varied range of applied sciences, Engineering, Medical,
                    and Technology. IUJAS serves as a platform for researchers,
                    academics, practitioners, and scholars to disseminate and
                    share their research findings, insights, and advancements
                    across a multitude of disciplines.
                </p>
                <h3>Aim</h3>
                <p>
                    The main aim of IUJAS is to promote the exchange of
                    knowledge and ideas in the realm of applied sciences by
                    providing a robust platform for the publication of
                    high-quality research. By simplifying the dissemination of
                    cutting-edge research, IUJAS struggles to contribute to the
                    advancement of several fields, including Applied Science,
                    Engineering, Medical Sciences, and Technology. Through
                    rigorous peer-review processes, IUJAS aims to safeguard the
                    authenticity, credibility, and validity of the research
                    published in its pages.
                </p>
                <h3>Scope</h3>
                <p>
                    IUJAS welcomes submissions that discover a wide-ranging of
                    topics within the realm of applied sciences. The journal
                    encourages the submission of the following types of
                    contributions:
                </p>
                <ul>
                    <li>
                        <strong>Articles:</strong> Original research articles
                        that present novel results, methodologies, and theories
                        in the fields of Applied Science, Engineering, Medical
                        Sciences, and Technology.
                    </li>
                    <li>
                        <strong>Reviews:</strong> Comprehensive reviews that
                        analyse and synthesize existing literature, identifying
                        trends, gaps, and future directions in various areas of
                        applied sciences.
                    </li>
                    <li>
                        <strong>Conference Papers:</strong> Extended versions of
                        conference papers that delve deeper into the research
                        presented at academic conferences, providing in-depth
                        insights and results.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
