import { Component, OnInit } from "@angular/core";
import { News } from "src/app/interfaces/news";
import { NewsService } from "src/app/services/news.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-news-page",
    templateUrl: "./news-page.component.html",
    styleUrls: ["./news-page.component.scss"],
})
export class NewsPageComponent implements OnInit {
    baseUrl = environment.baseUrl;
    list: News[];
    constructor(private newsService: NewsService) {}
    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.newsService.getAll().subscribe((data) => {
            this.list = data;
            console.log(this.list);
        });
    }
}
