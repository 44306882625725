import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { News } from "src/app/interfaces/news";
import { NewsService } from "src/app/services/news.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-news-details-page",
    templateUrl: "./news-details-page.component.html",
    styleUrls: ["./news-details-page.component.scss"],
})
export class NewsDetailsPageComponent implements OnInit {
    baseUrl = environment.baseUrl;
    item: any;
    id: any;
    constructor(
        private newsService: NewsService,
        private route: ActivatedRoute
    ) {
        this.id = this.route.snapshot.paramMap.get("id");
    }

    ngOnInit(): void {
        this.getNewsDetails();
    }

    getNewsDetails() {
        this.newsService.getNewsById(this.id).subscribe((res) => {
            this.item = res;
        });
    }
}
